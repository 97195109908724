import React from 'react'
import Layout from '../../components/Layout'
import { graphql, Link } from 'gatsby'
import BlogPosts from '../../components/BlogPosts'
import * as tagsStyles from './tags.module.css'
import Pagination from '../../components/Pagination'

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const {
        allMarkdownRemark: { edges },
    } = data

    const { tagsNumPages, currentPage } = pageContext

    return (
        <Layout
            sectionName="blog"
            seoTitle={`Kubevious :: #${tag}`}
            seoKeywords={tag}
            seoDescription={`Learn more about #${tag} in our blog`}
        >
            <div className={tagsStyles.backWrapper}>
                <div className={tagsStyles.backButton}>
                    <Link to={'/blog'}>Back</Link>
                </div>
            </div>

            <h1 className={tagsStyles.header}>#{tag}</h1>

            <BlogPosts edges={edges} />

            {tagsNumPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    numPages={tagsNumPages}
                    section="tags"
                    value={tag}
                />
            )}
        </Layout>
    )
}

export default Tags

export const pageQuery = graphql`
    query TagPost($tag: String, $limit: Int!, $skip: Int!) {
        allMarkdownRemark(
            filter: { fields: {sourceInstanceName: {eq: "blog"}}, frontmatter: { tags: { in: [$tag] } } }
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 280)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        tags
                        author {
                            name
                            avatar
                        }
                    }
                    fields {
                        slug
                        readingTime {
                            minutes
                        }
                    }
                }
            }
        }
    }
`
